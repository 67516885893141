#information-container {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

#information-container a:hover {
  // color: #ffffff !important;
  text-decoration: underline !important;
}

#information-container {
  margin: 0;
}

#information-container article,
#information-container aside,
#information-container footer,
#information-container header,
#information-container nav,
#information-container section {
  display: block;
}

#information-container h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

#information-container figcaption,
#information-container figure,
#information-container main {
  display: block;
}

#information-container figure {
  margin: 1em 40px;
}

#information-container hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

#information-container pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

#information-container a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

#information-container abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

#information-container b,
#information-container strong {
  font-weight: inherit;
}

#information-container b,
#information-container strong {
  font-weight: bolder;
}

#information-container code,
#information-container kbd,
#information-container samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

#information-container dfn {
  font-style: italic;
}

#information-container mark {
  background-color: #ff0;
  color: #000;
}

#information-container small {
  font-size: 80%;
}

#information-container sub,
#information-container sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

#information-container sub {
  bottom: -0.25em;
}

#information-container sup {
  top: -0.5em;
}

#information-container audio,
#information-container video {
  display: inline-block;
}

#information-container audio:not([controls]) {
  display: none;
  height: 0;
}

#information-container img {
  border-style: none;
}

#information-container svg:not(:root) {
  overflow: hidden;
}

#information-container button,
#information-container input,
#information-container optgroup,
#information-container select,
#information-container textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

#information-container button,
#information-container input {
  overflow: visible;
}

#information-container button,
#information-container select {
  // text-transform: none;
}

#information-container button,
#information-container [type='button'],
#information-container [type='reset'],
#information-container [type='submit'] {
  -webkit-appearance: button;
}

#information-container button::-moz-focus-inner,
#information-container [type='button']::-moz-focus-inner,
#information-container [type='reset']::-moz-focus-inner,
#information-container [type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

#information-container button:-moz-focusring,
#information-container [type='button']:-moz-focusring,
#information-container [type='reset']:-moz-focusring,
#information-container [type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

#information-container fieldset {
  padding: 0.35em 0.75em 0.625em;
}

#information-container legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

#information-container progress {
  display: inline-block;
  vertical-align: baseline;
}

#information-container textarea {
  overflow: auto;
}

#information-container [type='checkbox'],
#information-container [type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

#information-container [type='number']::-webkit-inner-spin-button,
#information-container [type='number']::-webkit-outer-spin-button {
  height: auto;
}

#information-container [type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

#information-container [type='search']::-webkit-search-cancel-button,
#information-container [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

#information-container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

#information-container details,
#information-container menu {
  display: block;
}

#information-container summary {
  display: list-item;
}

#information-container canvas {
  display: inline-block;
}

#information-container template {
  display: none;
}

#information-container [hidden] {
  display: none;
}
