html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

#information-container {
  background: color-bg(
    body
  ); /* Fallback for when there is no custom background color defined. */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#information-container hr {
  border: 0;
  @include divider();
  margin-top: $spacing--shared-v;
  margin-bottom: $spacing--shared-v;
}

#information-container ul,
#information-container ol {
  margin-top: 0;
  padding-left: $spacing--shared-h;

  &:not(:last-child) {
    margin-bottom: $spacing--shared-v;
  }
}

#information-container ul {
  list-style: disc;
}

#information-container ol {
  list-style: decimal;
}

#information-container li {
  &:not(:last-child) {
    margin-bottom: $spacing--shared-v * 0.5;
  }
}

#information-container li > ul,
#information-container li > ol {
  margin-top: $spacing--shared-v * 0.5;
  margin-bottom: 0;
}

#information-container dl {
  margin-top: 0;
  margin-bottom: $spacing--shared-v;
}

#information-container dt {
  @include font-weight(strong);
}

#information-container dd {
  margin-left: $spacing--shared-h;
  margin-bottom: $spacing--shared-v;
}

#information-container img {
  border-radius: $img-radius;
}

#information-container img,
#information-container svg,
#information-container video {
  display: block;
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}

#information-container figure {
  margin: $spacing--figure-v $spacing--figure-h; /* Extra wide images within figure tags don't overflow the content area. */
}

#information-container figcaption {
  padding: $spacing--figcaption-v $spacing--figcaption-h;
}

// tables
#information-container table {
  @include font-size(table);
  border-collapse: collapse;
  margin-bottom: $spacing--shared-v;
  width: 100%;
}

#information-container tr {
  border-bottom: 1px solid color-border(divider);
}

#information-container th {
  @include font-weight(strong);
  text-align: left;
}

#information-container th,
#information-container td {
  padding: $spacing--table-cell-v $spacing--table-cell-h;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

#information-container .invert-color {
  hr {
    @include divider(false, inverse);
  }

  tr {
    border-bottom: 1px solid color-border(divider-inverse);
  }
}
