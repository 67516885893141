#information-container {
  @include font-size(base);
}

#information-container {
  color: color(base);
  font-size: 1.3rem;
}

#information-container,
#information-container button,
#information-container input,
#information-container select,
#information-container textarea {
  @include font-family(base);
  @include font-weight(base);
}

#information-container h1,
#information-container h2,
#information-container h3,
#information-container h4,
#information-container h5,
#information-container h6,
#information-container .h1,
#information-container .h2,
#information-container .h3,
#information-container .h4,
#information-container .h5,
#information-container .h6 {
  @include font-family(heading);
  @include font-weight(heading);
  clear: both;
  color: color(heading);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

#information-container h1,
#information-container .h1 {
  @include font-size(h1);
}

#information-container h2,
#information-container .h2 {
  @include font-size(h2);
}

#information-container h3,
#information-container .h3 {
  @include font-size(h3);
}

#information-container h4,
#information-container .h4 {
  @include font-size(h4);
}

#information-container h5,
#information-container .h5 {
  @include font-size(base);
}

#information-container h6,
#information-container .h6,
#information-container small,
#information-container .text-sm {
  @include font-size(sm);
}

#information-container .text-xs {
  @include font-size(xs);
}

#information-container .text-xxs {
  @include font-size(xxs);
}

#information-container figcaption {
  @include font-size(figcap);
  @include font-weight(figcap);
}

#information-container a {
  &:not(.button) {
    @include anchor-aspect(main);
    @include font-weight(link);
  }

  &.button-link {
    @include anchor-aspect(button-link);
    @include font-weight(button-link);
    text-transform: $link-button--transform;
  }

  &.func-link {
    @include anchor-aspect(func-link);
    @include font-weight(func-link);
    text-transform: $link-func--transform;
  }
}

#information-container h1,
#information-container .h1 {
  margin-top: $spacing--h1-t;
  margin-bottom: $spacing--h1-b;
}

#information-container h2,
#information-container .h2 {
  margin-top: $spacing--h2-t;
  margin-bottom: $spacing--h2-b;
}

#information-container h3,
#information-container .h3 {
  margin-top: $spacing--h3-t;
  margin-bottom: $spacing--h3-b;
}

#information-container h4,
#information-container h5,
#information-container h6,
#information-container .h4,
#information-container .h5,
#information-container .h6 {
  margin-top: $spacing--hx-t;
  margin-bottom: $spacing--hx-b;
}

#information-container p {
  margin-top: 0;
  //margin-bottom: $spacing--shared-v;
  margin-bottom: 0;
}

#information-container dfn,
#information-container cite,
#information-container em,
#information-container i {
  font-style: italic;
}

#information-container blockquote {
  @include font-size(bquote);
  margin-top: $spacing--shared-v;
  margin-bottom: $spacing--shared-v;
  margin-left: $spacing--shared-h;

  &::before {
    content: "\201C";
  }

  &::after {
    content: "\201D";
  }

  p {
    display: inline;
  }
}

#information-container address {
  border-width: 1px 0;
  border-style: solid;
  border-color: color-border(divider);
  padding: $spacing--shared-v 0;
  margin: 0 0 $spacing--shared-v;
}

#information-container pre,
#information-container pre h1,
#information-container pre h2,
#information-container pre h3,
#information-container pre h4,
#information-container pre h5,
#information-container pre h6,
#information-container pre .h1,
#information-container pre .h2,
#information-container pre .h3,
#information-container pre .h4,
#information-container pre .h5,
#information-container pre .h6 {
  @include font-family(pre);
}

#information-container pre,
#information-container code,
#information-container kbd,
#information-container tt,
#information-container var {
  background: color-bg(code);
}

#information-container pre {
  @include font-size(pre);
  max-width: 100%;
  overflow: auto;
  padding: $spacing--shared-v $spacing--shared-h;
  margin-top: $spacing--shared-v;
  margin-bottom: $spacing--shared-v;
}

#information-container code,
#information-container kbd,
#information-container tt,
#information-container var {
  @include font-family(code);
  @include font-size(code);
  padding: $spacing--code-v $spacing--code-h;
}

#information-container abbr,
#information-container acronym {
  cursor: help;
}

#information-container mark,
#information-container ins {
  text-decoration: none;
}

#information-container b,
#information-container strong {
  @include font-weight(strong);
}

#information-container .invert-color {
  color: color(base-inverse);

  a {
    &:not(.button) {
      @include anchor-aspect(main, inverse);
    }

    &.button-link {
      @include anchor-aspect(button-link, inverse);
    }

    &.func-link {
      @include anchor-aspect(func-link, inverse);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: color(heading-inverse);
  }

  address {
    border-color: color-border(divider-inverse);
  }

  pre,
  code,
  kbd,
  tt,
  var {
    background: color-bg(code-inverse);
  }
}

@include media("<=medium") {
  #information-container .h1-mobile {
    @include font-size(h1);
  }

  #information-container .h2-mobile {
    @include font-size(h2);
  }

  #information-container .h3-mobile {
    @include font-size(h3);
  }

  #information-container .h4-mobile {
    @include font-size(h4);
  }

  #information-container .h5-mobile {
    @include font-size(base);
  }

  #information-container .h6-mobile {
    @include font-size(sm);
  }
}

@include media(">medium") {
  #information-container {
    @include font-size(base-desktop);
  }

  #information-container h1,
  #information-container .h1 {
    @include font-size(h1-desktop);
  }

  #information-container h2,
  #information-container .h2 {
    @include font-size(h2-desktop);
  }

  #information-container h3,
  #information-container .h3 {
    @include font-size(h3-desktop);
  }

  #information-container h4,
  #information-container .h4 {
    @include font-size(h4-desktop);
  }

  #information-container h5,
  #information-container .h5 {
    @include font-size(base-desktop);
  }

  #information-container h6,
  #information-container .h6,
  #information-container small,
  #information-container .text-sm {
    @include font-size(sm-desktop);
  }

  #information-container .text-xs {
    @include font-size(xs-desktop);
  }

  #information-container .text-xxs {
    @include font-size(xxs-desktop);
  }

  #information-container figcaption {
    @include font-size(figcap-desktop);
  }

  #information-container blockquote {
    @include font-size(bquote-desktop);
  }
}
