#information-container .tiles-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -($tiles-items--padding__mobile * 0.5);
  margin-left: -($tiles-items--padding__mobile * 0.5);
  margin-top: -($tiles-items--padding__mobile * 0.5);

  &:last-of-type {
    margin-bottom: -($tiles-items--padding__mobile * 0.5);
  }

  &:not(:last-of-type) {
    margin-bottom: ($tiles-items--padding__mobile * 0.5);
  }

  &.push-left {
    &::after {
      content: "";
      flex-basis: $tiles-item--width;
      max-width: $tiles-item--width;
      box-sizing: content-box;
      padding-left: $tiles-items--padding__mobile * 0.5;
      padding-right: $tiles-items--padding__mobile * 0.5;
      height: 0;
    }
  }
}

#information-container .tiles-item {
  flex-basis: $tiles-item--width;
  max-width: $tiles-item--width;
  box-sizing: content-box;
  padding: $tiles-items--padding__mobile * 0.5;

  * {
    box-sizing: border-box;
  }
}

#information-container .tiles-item-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  padding: $tiles-item--inner-padding-v $tiles-item--inner-padding-h;
}

@if ($tiles-items--padding__desktop != null) {
  @include media(">medium") {
    #information-container .tiles-wrap {
      margin-right: -($tiles-items--padding__desktop * 0.5);
      margin-left: -($tiles-items--padding__desktop * 0.5);
      margin-top: -($tiles-items--padding__desktop * 0.5);

      &:last-of-type {
        margin-bottom: -($tiles-items--padding__desktop * 0.5);
      }

      &:not(:last-of-type) {
        margin-bottom: ($tiles-items--padding__desktop * 0.5);
      }

      &.push-left {
        &::after {
          padding-left: $tiles-items--padding__desktop * 0.5;
          padding-right: $tiles-items--padding__desktop * 0.5;
        }
      }
    }

    #information-container .tiles-item {
      padding: $tiles-items--padding__desktop * 0.5;
    }
  }
}
