#information-container [class*='illustration-'] {
  position: relative;

  &::after {
    pointer-events: none;
  }
}

#information-container [class*='illustration-element-']::after {
  z-index: -1;
}

#information-container [class*='illustration-section-']::after {
  z-index: -2;
}

// Hero illustration
#information-container .illustration-section-01::after {
  @include illustration('illustration-section-01.svg', 100%, 286px, top);
}

#information-container .illustration-section-5E90CC::after {
  @include illustration('illustration-section-5E90CC.svg', 100%, 286px, top);
}

#information-container .illustration-section-2442AF::after {
  @include illustration('illustration-section-2442AF.svg', 100%, 286px, top);
}

#information-container .illustration-section-9b52e1::after {
  @include illustration('illustration-section-9b52e1.svg', 100%, 286px, top);
}

#information-container .illustration-section-8C7CF0::after {
  @include illustration('illustration-section-8C7CF0.svg', 100%, 286px, top);
}

#information-container .illustration-section-44a574::after {
  @include illustration('illustration-section-44a574.svg', 100%, 286px, top);
}

#information-container .illustration-section-CB3C1D::after {
  @include illustration('illustration-section-CB3C1D.svg', 100%, 286px, top);
}
// Features illustration 02
#information-container .illustration-section-02::after {
  @include illustration(
    'illustration-section-02.svg',
    100%,
    253px,
    null,
    null,
    30px
  );
}
