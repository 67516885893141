#information-container .site-header {
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: 10 !important;
  background: color-bg(header);

  + .site-content {
    .section:first-of-type {
      padding-top: $header-height__mobile;
    }
  }

  .brand {
    margin-right: 17px;
  }
}

#information-container .site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height__mobile;
}

#information-container .header-nav {
  flex-grow: 1;

  .header-nav-inner {
    display: flex;
    flex-grow: 1;
  }

  ul {
    display: flex;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;

    &:first-of-type {
      flex-wrap: wrap;
    }
  }

  li {
    + .header-button {
      margin-left: $header-nav--padding-h;
    }
  }

  a:not(.button) {
    display: block;
    @include anchor-aspect(header);
    @include font-weight(header-link);
    text-transform: $link-header--transform;
    padding: 0 $header-nav--padding-h;

    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
  }

  a.button {
    // margin-left: $header-nav--padding-h;
    margin-left: 0.85rem;

    @include media('<=large') {
      font-size: 0.6rem;
      margin-left: 0.8rem;
      padding: 4px 16px;
    }
  }
}

#information-container .header-nav-center:first-of-type {
  flex-grow: 1;
  justify-content: flex-end;
}

#information-container .header-nav-right {
  justify-content: flex-end;

  + .header-nav-right {
    flex-grow: 0;
  }
}

#information-container .header-nav-toggle {
  display: none;
}

@include media('<=medium') {
  #information-container .header-nav-toggle {
    display: block;
    // Header navigation when the hamburger is a previous sibling
    + .header-nav {
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      z-index: 9999;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.15s ease-in-out, opacity 0.05s;

      &.is-active {
        opacity: 1;
      }

      &.light {
        background: darken(get-color(light, 1), 3%);
      }

      &.dark {
        background: darken(get-color(dark, 1), 3%);
      }

      .header-nav-inner {
        flex-direction: column;
        padding: $header-nav--padding-v__mobile;
      }

      ul {
        display: block;
        text-align: center;

        a:not(.button) {
          display: inline-flex;
          @include anchor-aspect(header-mobile);
          padding-top: $header-nav--padding-v__mobile * 0.5;
          padding-bottom: $header-nav--padding-v__mobile * 0.5;
        }
      }

      a.button {
        margin-left: 0;
        margin-top: $header-nav--padding-v__mobile * 0.5;
        margin-bottom: $header-nav--padding-v__mobile * 0.5;
      }
    }
  }
}

@include media('>medium') {
  #information-container .site-header {
    + .site-content {
      .section:first-of-type {
        padding-top: $header-height__desktop;
      }
    }
  }

  #information-container .site-header-inner {
    height: $header-height__desktop;
  }
}
