// Hide elements, for screen readers only
#information-container .screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Reset lists style
#information-container .list-reset {
  list-style: none;
  padding: 0;

  li {
    margin: 0;
  }
}

// Text alignment
#information-container .ta-l {
  text-align: left;
}

#information-container .ta-c {
  text-align: center;
}

#information-container .ta-r {
  text-align: right;
}

// Font weight
#information-container .fw-400 {
  font-weight: 400;
}

#information-container .fw-500 {
  font-weight: 500;
}

#information-container .fw-600 {
  font-weight: 600;
}

#information-container .fw-700 {
  font-weight: 700;
}

// Text color
#information-container .text-color-high {
  color: color(high-contrast);
}

#information-container .text-color-mid {
  color: color(mid-contrast);
}

#information-container .text-color-low {
  color: color(low-contrast);
}

#information-container .background-primary {
  background-color: color(primary);
}

#information-container .text-color-primary {
  color: color(primary);
}

#information-container .text-color-secondary {
  color: color(secondary);
}

#information-container .text-color-error {
  color: color(error);
}

#information-container .text-color-warning {
  color: color(warning);
}

#information-container .text-color-success {
  color: color(success);
}

#information-container .invert-color {
  .text-color-high {
    color: color(high-contrast-inverse);
  }

  .text-color-mid {
    color: color(mid-contrast-inverse);
  }

  .text-color-low {
    color: color(low-contrast-inverse);
  }
}

// Text transform
#information-container .tt-u {
  text-transform: uppercase;
}

// Images
#information-container .image-full {
  width: 100%;
}

#information-container .image-larger {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + #{$image-larger--extra-width * 2});
  max-width: 100vw;
}

// Helper class for coloured bg
#information-container .has-bg-color {
  position: relative;
  // background-color: color-bg(bg-color);
  z-index: 0;
}

// Helper class for box shadow
#information-container .has-shadow {
  box-shadow: color-bg(shadow);
}

#information-container img.has-shadow {
  border-radius: $img-radius;
}

// Dividers
#information-container .has-top-divider {
  @include divider(before);
}

#information-container .has-bottom-divider {
  @include divider(after);
}

#information-container .invert-color {
  .has-top-divider {
    @include divider(before, inverse);
  }

  .has-bottom-divider {
    @include divider(after, inverse);
  }
}

// For centering content
#information-container .center-content {
  text-align: center;

  img,
  svg,
  video {
    margin-left: auto;
    margin-right: auto;
  }

  .button-group {
    justify-content: center;
  }
}

// Responsive video
#information-container .responsive-video {
  position: relative;
  padding-bottom: 56.25%; // 16:9
  height: 0;

  &.is-4-3 {
    padding-bottom: 75%; // 4:3
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Spacing, margin
#information-container .m-0 {
  margin: 0;
}

#information-container .mt-0 {
  margin-top: 0;
}

#information-container .mr-0 {
  margin-right: 0;
}

#information-container .mb-0 {
  margin-bottom: 0;
}

#information-container .ml-0 {
  margin-left: 0;
}

#information-container .m-4 {
  margin: 4px;
}

#information-container .mt-4 {
  margin-top: 4px;
}

#information-container .mr-4 {
  margin-right: 4px;
}

#information-container .mb-4 {
  margin-bottom: 4px;
}

#information-container .ml-4 {
  margin-left: 4px;
}

#information-container .m-8 {
  margin: 8px;
}

#information-container .mt-8 {
  margin-top: 8px;
}

#information-container .mr-8 {
  margin-right: 8px;
}

#information-container .mb-8 {
  margin-bottom: 8px;
}

#information-container .ml-8 {
  margin-left: 8px;
}

#information-container .m-12 {
  margin: 12px;
}

#information-container .mt-12 {
  margin-top: 12px;
}

#information-container .mr-12 {
  margin-right: 12px;
}

#information-container .mb-12 {
  margin-bottom: 12px;
}

#information-container .ml-12 {
  margin-left: 12px;
}

#information-container .m-16 {
  margin: 16px;
}

#information-container .mt-16 {
  margin-top: 16px;
}

#information-container .mr-16 {
  margin-right: 16px;
}

#information-container .mb-16 {
  margin-bottom: 16px;
}

#information-container .ml-16 {
  margin-left: 16px;
}

#information-container .m-24 {
  margin: 24px;
}

#information-container .mt-24 {
  margin-top: 24px;
}

#information-container .mr-24 {
  margin-right: 24px;
}

#information-container .mb-24 {
  margin-bottom: 24px;
}

#information-container .ml-24 {
  margin-left: 24px;
}

#information-container .m-32 {
  margin: 32px;
}

#information-container .mt-32 {
  margin-top: 32px;
}

#information-container .mr-32 {
  margin-right: 32px;
}

#information-container .mb-32 {
  margin-bottom: 32px;
}

#information-container .ml-32 {
  margin-left: 32px;
}

// Spacing, padding
#information-container .p-0 {
  padding: 0;
}

#information-container .pt-0 {
  padding-top: 0;
}

#information-container .pr-0 {
  padding-right: 0;
}

#information-container .pb-0 {
  padding-bottom: 0;
}

#information-container .pl-0 {
  padding-left: 0;
}

#information-container .p-4 {
  padding: 4px;
}

#information-container .pt-4 {
  padding-top: 4px;
}

#information-container .pr-4 {
  padding-right: 4px;
}

#information-container .pb-4 {
  padding-bottom: 4px;
}

#information-container .pl-4 {
  padding-left: 4px;
}

#information-container .p-8 {
  padding: 8px;
}

#information-container .pt-8 {
  padding-top: 8px;
}

#information-container .pr-8 {
  padding-right: 8px;
}

#information-container .pb-8 {
  padding-bottom: 8px;
}

#information-container .pl-8 {
  padding-left: 8px;
}

#information-container .p-12 {
  padding: 12px;
}

#information-container .pt-12 {
  padding-top: 12px;
}

#information-container .pr-12 {
  padding-right: 12px;
}

#information-container .pb-12 {
  padding-bottom: 12px;
}

#information-container .pl-12 {
  padding-left: 12px;
}

#information-container .p-16 {
  padding: 16px;
}

#information-container .pt-16 {
  padding-top: 16px;
}

#information-container .pr-16 {
  padding-right: 16px;
}

#information-container .pb-16 {
  padding-bottom: 16px;
}

#information-container .pl-16 {
  padding-left: 16px;
}

#information-container .p-24 {
  padding: 24px;
}

#information-container .pt-24 {
  padding-top: 24px;
}

#information-container .pr-24 {
  padding-right: 24px;
}

#information-container .pb-24 {
  padding-bottom: 24px;
}

#information-container .pl-24 {
  padding-left: 24px;
}

#information-container .p-32 {
  padding: 32px;
}

#information-container .pt-32 {
  padding-top: 32px;
}

#information-container .pr-32 {
  padding-right: 32px;
}

#information-container .pb-32 {
  padding-bottom: 32px;
}

#information-container .pl-32 {
  padding-left: 32px;
}

// Spacing, spacers
#information-container .spacer-4 {
  padding-top: 4px;
}

#information-container .spacer-8 {
  padding-top: 8px;
}

#information-container .spacer-12 {
  padding-top: 12px;
}

#information-container .spacer-16 {
  padding-top: 16px;
}

#information-container .spacer-24 {
  padding-top: 24px;
}

#information-container .spacer-32 {
  padding-top: 32px;
}

#information-container .spacer-48 {
  padding-top: 48px;
}

#information-container .spacer-64 {
  padding-top: 64px;
}

@include media('<=medium') {
  #information-container .ta-l-mobile {
    text-align: left;
  }

  #information-container .ta-c-mobile {
    text-align: center;
  }

  #information-container .ta-r-mobile {
    text-align: right;
  }

  #information-container .center-content-mobile {
    text-align: center;

    img,
    svg,
    video {
      margin-left: auto;
      margin-right: auto;
    }

    .button-group {
      justify-content: center;
    }
  }

  // Spacing, spacers
  #information-container .spacer-4-mobile {
    padding-top: 4px;
  }

  #information-container .spacer-8-mobile {
    padding-top: 8px;
  }

  #information-container .spacer-12-mobile {
    padding-top: 12px;
  }

  #information-container .spacer-16-mobile {
    padding-top: 16px;
  }

  #information-container .spacer-24-mobile {
    padding-top: 24px;
  }

  #information-container .spacer-32-mobile {
    padding-top: 32px;
  }

  #information-container .spacer-48-mobile {
    padding-top: 48px;
  }

  #information-container .spacer-64-mobile {
    padding-top: 64px;
  }
}

@include media('>medium') {
  #information-container .ta-l-desktop {
    text-align: left;
  }

  #information-container .ta-c-desktop {
    text-align: center;
  }

  #information-container .ta-r-desktop {
    text-align: right;
  }

  #information-container .center-content-desktop {
    text-align: center;

    img,
    svg,
    video {
      margin-left: auto;
      margin-right: auto;
    }

    .button-group {
      justify-content: center;
    }
  }
}
