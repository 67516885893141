#information-container h3,
#information-container h4,
#information-container h5,
#information-container h6,
#information-container .h3,
#information-container .h4,
#information-container .h5,
#information-container .h6 {
  font-weight: 700;
}
