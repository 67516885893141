#information-container .features-tiles {
  .section-inner {
    padding-top: $features-tiles--padding-t__mobile;
    padding-bottom: $features-tiles--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $features-tiles-header--padding__mobile;
  }

  .tiles-wrap {
    @if ($features-tiles-items--padding__mobile != null) {
      margin-right: -($features-tiles-items--padding__mobile * 0.5);
      margin-left: -($features-tiles-items--padding__mobile * 0.5);
      margin-top: -($features-tiles-items--padding__mobile * 0.5);

      &:last-of-type {
        margin-bottom: -($features-tiles-items--padding__mobile * 0.5);
      }

      &:not(:last-of-type) {
        margin-bottom: ($features-tiles-items--padding__mobile * 0.5);
      }
    }

    &.push-left {
      &::after {
        flex-basis: $features-tiles-item--width;
        max-width: $features-tiles-item--width;
        @if ($features-tiles-items--padding__mobile != null) {
          padding-left: $features-tiles-items--padding__mobile * 0.5;
          padding-right: $features-tiles-items--padding__mobile * 0.5;
        }
      }
    }
  }

  .tiles-item {
    flex-basis: $features-tiles-item--width;
    max-width: $features-tiles-item--width;
    @if ($features-tiles-items--padding__mobile != null) {
      padding: $features-tiles-items--padding__mobile * 0.5;
    }
  }

  .tiles-item-inner {
    padding-top: $features-tiles-item--inner-padding-v;
    padding-bottom: $features-tiles-item--inner-padding-v;
    padding-left: $features-tiles-item--inner-padding-h;
    padding-right: $features-tiles-item--inner-padding-h;
  }
}

@include media(">medium") {
  #information-container .features-tiles {
    .section-inner {
      padding-top: $features-tiles--padding-t__desktop;
      padding-bottom: $features-tiles--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $features-tiles-header--padding__desktop;
    }

    @if ($features-tiles-items--padding__desktop != null) {
      .tiles-wrap {
        margin-right: -($features-tiles-items--padding__desktop * 0.5);
        margin-left: -($features-tiles-items--padding__desktop * 0.5);
        margin-top: -($features-tiles-items--padding__desktop * 0.5);

        &:last-of-type {
          margin-bottom: -($features-tiles-items--padding__desktop * 0.5);
        }

        &:not(:last-of-type) {
          margin-bottom: ($features-tiles-items--padding__desktop * 0.5);
        }

        &.push-left {
          &::after {
            padding-left: $features-tiles-items--padding__desktop * 0.5;
            padding-right: $features-tiles-items--padding__desktop * 0.5;
          }
        }
      }

      .tiles-item {
        padding: $features-tiles-items--padding__desktop * 0.5;
      }
    }
  }
}
